@import 'styles/includes';

.container {
  position: sticky;
  top: 0;
  z-index: 10;
  height: 80px;
  overflow-y: scroll;
  background-color: color(white);
  box-shadow: #b5bbc226 0 0 10px 0;

  $default-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);

  @include breakpoint-lg {
    overflow-y: unset;
  }

  hr {
    margin: gutter(0.4) 0;
  }

  .inner {
    @include keep-within-width;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto auto;
    gap: 1rem;
    align-items: center;
    min-height: 5rem;

    @include breakpoint-lg {
      display: flex;
      flex-direction: row;
      gap: 3rem;
      justify-content: flex-start;
    }

    .logo {
      flex-shrink: 0;
    }

    .toggle {
      justify-self: flex-end;
      padding: 0;
      color: inherit;
      appearance: none;
      cursor: pointer;
      background: none;
      border: none;
      outline: inherit;

      @include breakpoint-lg {
        display: none;
      }
    }

    .navigation {
      position: absolute;
      top: 100%;
      width: 100%;
      padding: 0 $gutter;
      background: white;

      @include breakpoint-lg {
        position: relative;
        top: unset;
        z-index: 20;
        display: flex;
        flex-grow: 1;
        width: unset;
        background: unset;
      }

      @include breakpoint-xl {
        margin-left: 4rem;
      }

      > .links {
        display: none;
        width: 100%;
        padding-left: 0;
        list-style-type: none;

        @include breakpoint-lg {
          display: flex;
          gap: 3rem;
          align-items: center;
        }

        .item {
          position: relative;
          flex-shrink: 0;
          width: 100%;

          @include breakpoint-lg {
            width: unset;
          }

          &.divider {
            margin: 1rem 0;
            border-bottom: 1px solid color(ash);

            @include breakpoint-lg {
              height: 1rem;
              margin: unset;
              border-bottom: unset;
              border-left: 1px solid color(ash);

              &:last-of-type {
                display: none;
              }
            }
          }

          .link {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .badge {
              background-color: color-opacity(cerulean, 0.25);
              color: color(cerulean);
              padding: 0 0.5rem;
              border-radius: 1rem;
              font-size: 0.75rem;
              font-weight: 500;
            }
          }

          .label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0.5rem 0;
            cursor: pointer;

            &.vertical {
              padding: 0;

              .icon {
                width: 1.25rem;
                height: 1.25rem;
              }
            }

            .icon {
              width: 1rem;
              height: 1rem;
              margin-left: 0.5rem;
              fill: color(battleship-grey);
            }
          }

          $submenu-offset: 4px;

          &:hover,
          &:focus,
          &:focus-within {
            .label {
              color: color(sunset);

              .icon {
                fill: color(sunset);
              }
            }

            .submenu {
              display: block;
            }

            .icon {
              fill: color(sunset);
            }

            // fixes loss of focus when hovering over submenu
            .submenu.vertical::before {
              position: absolute;
              top: 0;
              bottom: 0;
              left: calc(100%); // start from the right edge of the submenu
              z-index: 26; // just above the menu's z-index
              width: calc(gutter(1) + $submenu-offset);
              content: '';
            }
          }

          .submenu {
            display: none;
            max-height: 40vh;
            padding-left: gutter(2);
            overflow: hidden scroll;
            list-style-type: none;
            border-radius: 5px;

            &.vertical {
              padding: gutter(0.25) gutter(0.5);
            }

            @include breakpoint-lg {
              $x-padding: gutter(1);
              position: absolute;
              top: 100%;
              z-index: 25;
              flex-direction: column;
              padding: 0.5rem $x-padding;
              background-color: color(white);
              box-shadow: $default-shadow;

              &.vertical {
                top: 0%;
                right: calc(100% + $x-padding + $submenu-offset);
                width: 100%;
              }
            }

            %submenu-item {
              display: block !important;
              line-clamp: 1;

              @include breakpoint-lg {
                min-width: 5rem;
                padding: 0.5em 0.75em;
                font-size: 14px;
                line-height: 21px;
                color: color(battleship-grey);
              }

              .link {
                display: block;
                width: unset;
                padding: 0.5rem 0;
                overflow: hidden;
                text-align: start;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;

                @include breakpoint-lg {
                  width: unset;
                  padding: 0;
                }
              }

              &:hover,
              &:focus,
              &:focus-within {
                color: color(sunset);
              }
            }

            .item {
              @extend %submenu-item;
            }

            .icon-item {
              @extend %submenu-item;
              display: flex;
              column-gap: gutter(0.5);
              align-items: center;
              margin: gutter(0.3) gutter(0.3);

              // EW: I am sorry for you if you read this.
              border: none !important;
            }
          }
        }

        .profile {
          position: relative;

          @include breakpoint-lg {
            margin-left: auto;
          }

          .avatar {
            display: none;

            @include breakpoint-lg {
              display: block;
            }
          }

          .icon {
            @include breakpoint-lg {
              display: block;
              cursor: pointer;
              border: 2px solid transparent;
            }
          }

          $offset: -40%;

          .organization-image {
            position: absolute;
            top: $offset;
            left: $offset;
            border: color(white) 3px solid;
            box-shadow: $default-shadow;
            transform: scale(0.55);
          }

          .links {
            width: 100%;
            padding-left: 0;
            font-size: 18px;
            list-style-type: none;
            border-radius: 5px;

            @include breakpoint-lg {
              position: absolute;
              top: 100%;
              right: 0;
              display: none;
              width: unset;
              padding: gutter(0.75) gutter(1);
              background-color: color(white);
              box-shadow: $default-shadow;
            }

            %item {
              width: 100%;
              padding: 0.6rem 0;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
              border-bottom: 2px solid transparent;
              transition: all 0.1s;

              &:hover {
                font-weight: 600;
                border-bottom-color: color(sunset);
              }

              @include breakpoint-lg {
                display: inline-block;
                font-size: 18px;
                font-weight: unset;
                border-bottom: unset;

                &:hover {
                  font-weight: unset;
                }
              }

              .link {
                width: 100%;
                text-decoration: none;

                @include breakpoint-lg {
                  &:hover {
                    color: color(sunset);
                  }
                }
              }
            }

            .item {
              @extend %item;
            }

            .icon-item {
              @extend %item;
              display: flex;
              column-gap: gutter(0.5);
              align-items: center;
            }
          }

          &:hover,
          &:focus,
          &:focus-within {
            @include breakpoint-lg {
              .links {
                display: block;
              }

              .icon {
                border-radius: 100%;
              }
            }
          }
        }
      }
    }
  }

  &.open {
    width: 100vw;
    height: 100vh;
    box-shadow: none;

    .inner {
      .navigation {
        .links {
          display: block;
        }
      }
    }
  }
}

.flex {
  display: flex !important;
  column-gap: gutter(0.5);
  align-items: center;

  svg {
    path {
      fill: currentcolor;
    }
  }
}

.bold {
  font-weight: 600;
}
