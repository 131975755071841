@import 'styles/includes';

.image {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.shape {
  &-round {
    border-radius: 50%;
  }

  &-rectangular {
    border-radius: 5%;
  }
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1;
  font-weight: 800;
  color: color(white);
  text-align: center;
  border-radius: 50%;
}

.color {
  @each $name, $color in $colors {
    &-#{$name} {
      background-color: $color;
    }
  }
}

.size {
  $base-size: 2rem;

  $sizes: (
    xsmall: 0.5,
    small: 1,
    medium: 1.5,
    large: 3,
    xlarge: 4,
  );

  @each $label, $multiplier in $sizes {
    &-#{$label} {
      $calculated-size: $base-size * $multiplier;
      width: $calculated-size;
      min-width: $calculated-size;
      max-width: $calculated-size;
      height: $calculated-size;
      min-height: $calculated-size;
      max-height: $calculated-size;
    }
  }
}

.outline {
  border: 1px solid color(battleship-grey);
}
