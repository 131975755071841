@import 'styles/includes';

.background {
  background-color: color(pearl);
}

.container {
  display: flex;
  flex-direction: row;
  gap: gutter(2);
  justify-content: flex-start;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 8px;
  list-style: none;

  @include keep-within-width;
}

.link {
  display: inline-flex;
  height: 1rem;
  font-size: 12px;
  color: color(battleship-grey);
  text-decoration: none;

  &:hover {
    color: color(sunset);
  }
}
