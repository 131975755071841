@import 'styles/includes';

.container {
  @include keep-within-width(false);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: dense; // TODO: Not correct everytime. Needs new logic for positioning.

  &.center-vertical,
  &.center-both {
    align-items: center;
  }

  &.center-horizontal,
  &.center-both {
    justify-items: center;
  }

  & + &,
  & + div {
    margin-top: gutter(2);
  }

  &.gap-none {
    grid-gap: 0;
  }

  &.gap-single {
    grid-gap: gutter(1);
  }

  &.gap-double {
    grid-gap: gutter(2);
  }
}
