@import 'styles/includes';

.container {
  margin-bottom: 0.5rem;
  font-family: 'Basier Circle', 'Arial', 'Helvetica', sans-serif;
  font-weight: 600;

  &.bold {
    font-weight: 600;
  }

  &.regular {
    font-weight: 400;
  }
}
