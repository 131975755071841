$colors: (
  white: white,
  black: black,
  transparent: transparent,
  sunset: #fd9274,
  malachite: #005151,
  deathstar-grey: #212529,
  battleship-grey: #646b64,
  clay: #8b8c7b,
  ash: #b7b8aa,
  fog: #d5d5c7,
  pearl: #f2f3ef,
  ice: #e9ecef,
  amaranth: #a63a79,
  orange: #ff6915,
  cerulean: #0288ae,
  gold: #d3a159,
  dunesea-sand: #ffb27d,
  mint-green: #b2edd1,
  ui-green: #5ca04c,
  ui-yellow: #fed90b,
  ui-red: #f93d1c,
  darker-background: #d5d5c8,
);

@function color($color) {
  @return map-get($colors, $color);
}

@function color-opacity($color, $opacity) {
  @return rgba(map-get($colors, $color), $opacity);
}
