@import 'styles/includes';

.container {
  width: 100%;
  color: color(white);
  background-color: color(malachite);

  .inner {
    @include keep-within-width;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
    padding: 0.5rem 1rem;
  }
}
