@import 'styles/includes';

.container {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 1fr;
  min-height: 100vh;

  &.no-header {
    grid-template-rows: 1fr auto;
  }

  &.pearl {
    background-color: color(pearl);
  }
}

.content {
  &.gap {
    display: flex;
    flex-direction: column;
    gap: gutter(4);
  }
}
