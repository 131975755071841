@use 'sass:math';

@import 'styles/includes';

.container {
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;
  appearance: none;

  &:hover {
    text-decoration: none;
  }

  &.inline {
    @each $name, $color in $colors {
      &.foreground-#{$name} {
        color: $color;

        &.disabled {
          opacity: 0.65;
        }
      }

      &.hover-#{$name} {
        &:focus,
        &:hover:not(.disabled) {
          color: $color;
        }
      }
    }
  }

  &.disabled {
    color: color(battleship-grey);
    text-decoration-style: dotted;
    text-decoration-color: color(battleship-grey);
    pointer-events: none;

    &:not(button) {
      display: inline-flex;
      gap: 0.5rem;

      svg {
        display: inline;
        width: 1rem * math.div(15, 19);
        height: 1rem;
        margin-right: 1rem;
        margin-left: 1rem;
        transform: translateY(1px);
      }
    }

    &:hover {
      text-decoration-style: dotted;
    }
  }

  &.block {
    display: inline-block;
    padding: 0.5rem 0;
    white-space: nowrap;
    transition: all 0.1s;

    @each $name, $color in $colors {
      &.background-#{$name} {
        border-bottom: solid 2px rgba($color, 0%);

        /* stylelint-disable-line */
        &.active,
        &:focus:not(.disabled),
        &:hover:not(.disabled) {
          text-decoration: none;
          border-bottom: solid 2px $color;
          -webkit-text-stroke-width: 0.5px;
          -webkit-text-stroke-color: #212529;
        }

        &:hover:not(.disabled),
        &:focus:not(.disabled),
        &:global(.active):not(.disabled) {
          text-decoration: none;
          border-bottom: solid 2px $color;
          -webkit-text-stroke-width: 0.5px;
          -webkit-text-stroke-color: #212529;
        }
      }
    }
  }

  &.button {
    display: inline-block;
    align-items: center;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    border-radius: 8rem;
    transition: all 0.1s;

    &.small {
      padding: 0.35rem 0.75rem;
    }

    &:not(.small) {
      min-width: 128px;
    }

    &.hollow {
      background-color: transparent !important;
    }

    @each $name, $color in $colors {
      &.background-#{$name} {
        background-color: $color;
        border: 2px solid $color;

        &.disabled {
          color: $color;
          border-color: $color;
          opacity: 0.65;
        }
      }

      &.foreground-#{$name} {
        color: $color;

        &.disabled {
          background-color: $color;
          opacity: 0.65;
        }
      }

      &.hover-#{$name} {
        &:focus,
        &:hover:not(.disabled) {
          background-color: $color;
          border-color: $color;
        }
      }
    }
  }

  .spinner {
    margin-right: 0.5rem;
  }
}
