@import 'styles/includes';

.container {
  color: color(deathstar-grey);

  @each $name, $color in $colors {
    &.color-#{$name} {
      color: $color;
    }
  }

  &.size-extra-large {
    font-size: 24px;
    line-height: 28px;
  }

  &.size-large {
    font-size: 18px;
    line-height: 24px;
  }

  &.size-small {
    font-size: 14px;
    line-height: 16px;
  }

  &.size-xsmall {
    font-size: 12px;
    line-height: 14px;
  }

  &.style-semibold {
    font-weight: 500;
  }

  &.style-bold {
    font-weight: 600;
  }

  &.style-italic {
    font-style: italic;
  }
}
