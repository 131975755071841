/**
  Global overrides of element classes.
  Don't put classes here.
*/

* {
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  height: 100%;

  /**
    use 16 here no matter what size the root size is. A base of 16 is easy to work with in rems

    EXAMPLES
    ================================================
    0.625rem  =>    10px
    0.75rem   =>    12px
    0.875rem  =>    14px
    1rem      =>    16px
    1.125rem  =>    18px;
    1.5rem    =>    24px;
    ================================================
   */
  font-family: var(--font-regular);
  font-size: 16px;
}

body {
  height: 100%;
  line-height: 1.5;

  /**
    set the common text-size here in rems - not using pixels here makes the root still referrable as 16px
    here the base is 16. leave unchanged.
    font-size: 1rem;
    */
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  appearance: none;
}

a {
  font-size: 1em;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    opacity: var(--dim);
  }

  &:active,
  &:focus {
    text-decoration: underline;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

input {
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    appearance: none;
  }
}

#__next {
  isolation: isolate;
}
