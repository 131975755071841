@import 'includes';
@import 'overrides';

:root {
  --breakpoint-sm: #{$breakpoint-sm};
  --breakpoint-md: #{$breakpoint-md};
  --breakpoint-lg: #{$breakpoint-lg};
  --breakpoint-xl: #{$breakpoint-xl};
  --font-regular: #{$font-regular};
  --gutter: #{$gutter};
  overflow-x: hidden;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  color: #212529;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 17px;
}

h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

@include breakpoint-lg {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 21px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 1rem;
  }
}

hr {
  height: 1px;
  background: color(darker-background);
  border: none;
}
